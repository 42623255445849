import {
  computed,
  signal,
  WritableSignal,
} from '@angular/core';

import {
  EmptyGuid,
  FilterV2,
  FilterGroupV2Dto,
  FiltersPaneMode
} from '../';

import {
  ReferenceType
} from '../../_enums/referenceType';

export class FilterGroupV2 {
  public loading: WritableSignal<boolean> = signal<boolean>(false);

  public totalCheckedCount = computed<number>(() =>
    this.filters().reduce((acc, f) => acc + f.checkedCount(), 0));

  public totalVisibleCount = computed<number>(() => {
    let count = 0;
    this.filters().forEach((f) => {
      if (f.visible()) count++;
      f.children.forEach((c) => {
        if (c.visible()) count++;
      });
    });
    return count;
  });

  public totalFilterCount = computed<number>(() => {
    let count = 0;
    this.filters().forEach((f) => {
      if (f.enabled()) count++;
      f.children.forEach((c) => {
        if (c.enabled()) count++;
      });
    });
    return count;
  });

  public totalEnabledCount = computed<number>(() => {
    let count = 0;
    this.filters().forEach((f) => {
      if (f.enabled()) count++;
      f.children.forEach((c) => {
        if (c.enabled()) count++;
      });
    });
    return count;
  });

  public hierarchyParent: FilterGroupV2;
  private hierarchyChild: FilterGroupV2;
  private hierarchyUrl: string | null;
  private searchText: string = '';
  public editMode: WritableSignal<boolean> = signal<boolean>(false);
  public collapsed: WritableSignal<boolean> = signal<boolean>(false);
  public itemCount: number;
  public referenceType: ReferenceType;
  isAdding: boolean = false;
  isEditing: boolean = false;
  public id: string;
  public mode: FiltersPaneMode;
  public documentCount: WritableSignal<number> = signal(-1);

  constructor(
    public name: string,
    public filters: WritableSignal<FilterV2[]>,
    public whenEmptyMessage: string = '<empty>'
  ) { }

  public setHierarchyChild(child: FilterGroupV2, url: string): void {
    this.hierarchyChild = child;
    child.hierarchyParent = this;
    this.hierarchyUrl = url;
  }

  public getHierarchyChild(): FilterGroupV2 {
    return this.hierarchyChild;
  }

  public isHierarchical(): boolean {
    return this.hierarchyChild !== undefined;
  }

  public getUrl(): string {
    return this.hierarchyUrl;
  }

  public hierarchyParentName(): string | null {
    if (this.hierarchyParent !== undefined) return this.hierarchyParent.name;
    return null;
  }

  public getChecked(): FilterV2[] {
    var allChecked: FilterV2[] = [];
    this.filters().forEach(f => allChecked.push(...f.getChecked()));
    return allChecked;
  }

  public uncheckAll(): void {
    this.filters().forEach(f => f.uncheckAll());
  }

  public sort(): void {
    this.filters.set(this.filters().sort((a, b) => a.name.localeCompare(b.name)));
  }

  public static fromFilterGroupV2Dto(dto: FilterGroupV2Dto): FilterGroupV2 {
    let fg: FilterGroupV2 = new FilterGroupV2(dto.name, signal<FilterV2[]>(FilterV2.FromFilterV2Dtos(dto.filters)));
    fg.id = dto.id;
    return fg;
  }

  public static fromFilterGroupV2Dtos(dtos: FilterGroupV2Dto[]): FilterGroupV2[] {
    return dtos.map(dto => FilterGroupV2.fromFilterGroupV2Dto(dto));
  }

  public removeAllChildren(depth: number = 0): void {
    if (depth > 0) this.filters.set([]);
    if (this.hierarchyChild != null) this.hierarchyChild.removeAllChildren(depth + 1);
  }
  
  public getCheckedHierarchy(omitCurrent: boolean = false): FilterV2[][] {
    var checkedFilters: FilterV2[][] = [];
    if (this.hierarchyParent !== undefined) {
      checkedFilters = this.hierarchyParent.getCheckedHierarchy();
    }
    if (!omitCurrent) checkedFilters.push(this.getChecked());
    return checkedFilters;
  }
  
  public setFilters(filters: FilterV2[]): void {
    this.filters.set(filters);
//    this.evaluateFilters();
  }

  search(searchText: string): void {
    this.searchText = searchText.toLocaleLowerCase();
    this.evaluateFilters(false);
  }

  evaluateFilters(moveCheckedToTop: boolean): void {
    let readOnly = this.editMode() === false;
    let searching = this.searchText.length > 0;
    let collapsed = this.collapsed();

    if (this.filters().length > 0 && this.filters()[0].id === EmptyGuid && this.filters()[0].checked()) {
      this.filters().forEach((filter: FilterV2, index: number) => {
        if (index > 0) {
          filter.enabled.set(false);
          filter.checked.set(false);
          filter.visible.set(false);
          filter.children.forEach((child) => {
            child.enabled.set(false);
            filter.checked.set(false);
            filter.visible.set(false);
          });
        }
      });
    } else {
      if (moveCheckedToTop) {
        let checkedFilters: FilterV2[] = [];
        let uncheckedFilters: FilterV2[] = [];
        this.filters().forEach((filter) => {
          let childChecked: boolean = false;
          filter.children.forEach((child) => {
            if (child.checked()) childChecked = true;
          });
          if (childChecked || filter.checked()) checkedFilters.push(filter);
          else uncheckedFilters.push(filter);
        });
        checkedFilters = sortByName(checkedFilters);
        checkedFilters.forEach((filter) => {
          let checkedChildren: FilterV2[] = [];
          let uncheckedChildren: FilterV2[] = [];
          filter.children.forEach((child) => {
            if (child.checked()) checkedChildren.push(child);
            else uncheckedChildren.push(child);
          });
          checkedChildren = sortByName(checkedChildren);
          uncheckedChildren = sortByName(uncheckedChildren);
          filter.children = [...checkedChildren, ...uncheckedChildren];
        });
        uncheckedFilters = sortByName(uncheckedFilters);

        let index = checkedFilters.findIndex(filter => filter.id === EmptyGuid);
        let notApplicable: FilterV2 | undefined = undefined;
        if (index !== -1) {
          [notApplicable] = checkedFilters.splice(index, 1);
        } else {
          index = uncheckedFilters.findIndex(filter => filter.id === EmptyGuid);
          if (index !== -1) {
            [notApplicable] = uncheckedFilters.splice(index, 1);
          }
        }
        if (index !== -1) this.filters.set([notApplicable, ...checkedFilters, ...uncheckedFilters]);
        else this.filters.set([...checkedFilters, ...uncheckedFilters]);
      }
/*
      // 1st pass - figure out if any filters should be grey-ticked because their itemCount matched the number of documents on screen
      if (this.documentCount !== undefined && this.documentCount() > -1) {
        this.filters().forEach((filter) => {
          let implied: boolean = false;
          if (!filter.checked() && filter.itemCount == this.documentCount()) implied = true;
          filter.implied.set(implied);
          filter.children.forEach((child) => {
            let implied = false;
            if (!child.checked() && child.itemCount == this.documentCount()) implied = true;
            child.implied.set(implied);
          });
        });
      }
      */
      // 2nd pass: set the willBeVisible flag by applying the search text filter to every filter
      this.filters().forEach((filter) => {
        let enabledChild: boolean = false;
        filter.children.forEach((child) => {
          if (searching) child.willBeVisible = child.name.toLocaleLowerCase().includes(this.searchText);
          else child.willBeVisible = true;
          if (readOnly && !child.checked() && child.willBeVisible) child.willBeVisible = false;
          if (child.willBeVisible) enabledChild = true;
          child.enabled.set(child.willBeVisible);
        });
        if (searching) filter.willBeVisible = enabledChild || filter.name.toLocaleLowerCase().includes(this.searchText);
        else filter.willBeVisible = true;
        filter.enabled.set(filter.willBeVisible);
      });

      // 3rd pass: if a filter willBeVisible == true, apply the mode-based rules to the willBeVisible flag 
      if (this.mode == FiltersPaneMode.Document) {
        this.filters().forEach((filter) => {
          let visibleChild: boolean = false;
          if (filter.children.length > 0) {
            filter.children.forEach((child) => {
              if (child.enabled()) {
                if (!this.editMode() && !child.checked()) child.willBeVisible = false;
                if (child.willBeVisible) visibleChild = true;
              } else child.willBeVisible = false;
            });
          }
          if (filter.enabled()) {
            if (!this.editMode() && !filter.checked()) filter.willBeVisible = false;
            if (visibleChild) filter.willBeVisible = true;
          } else filter.willBeVisible = false;
        });
      }

      // 4th pass: consider if the filters should be collapsed and if so, hide the 4th visible filter and beyond
      if (collapsed) {
        let visibleCount: number = 0;
        this.filters().forEach((filter) => {
          if (filter.willBeVisible) {
            if (visibleCount < 3) visibleCount++;
            else filter.willBeVisible = false;
            filter.children.forEach((child) => {
              if (child.willBeVisible) {
                if (visibleCount < 3) visibleCount++;
                else child.willBeVisible = false;
              }
            });
          }
        });
      } else {
        this.filters().forEach((filter) => {
          if (filter.enabled()) filter.willBeVisible = true;
          filter.children.forEach((child) => {
            if (child.enabled()) child.willBeVisible = true;
          });
        });
      }

      // 5th pass: set the visible signal to the value of willBeVisible
      this.filters().forEach((filter) => {
        filter.visible.set(filter.willBeVisible);
        filter.children.forEach((child) => {
          child.visible.set(child.willBeVisible);
        });
      });
    }
  }

  public setCheckedFilters(filters: FilterV2[]) {
    if (this.filters().length > 0) {
      let context = this.filters()[0].context;
      let refType = this.filters()[0].referenceType;
      filters.forEach((filter) => {
        if (filter.context == context && filter.referenceType == refType) {
          let found = this.filters().find((f) => f.id == filter.id);
          if (found !== undefined) {
            found.checked.set(true);
          }
        }
      });
    }
  }

  public childGroupHasChecked(): boolean {
    if (this.totalCheckedCount() > 0) return true;
    if (this.hierarchyChild === undefined) return false;
    return this.hierarchyChild.childGroupHasChecked();
  }
}

const sortByName = (array: FilterV2[]): FilterV2[] => {
  return array.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};




