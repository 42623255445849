<header>
  <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
    <!-- Sidebar -->
    <div class="layout-sidebar sidebar-fixed position-fixed" id="navbarSupportedContent">
      <div class="logo_section d-table-cell align-middle">
        <a routerLink="/home/welcome"><img src="assets/images/Modutecture-logo.png"></a>
      </div>
      <div id="navigation-scrollable">
        <div class="list-group list-group-flush" *ngIf="!inLibrary || !libraryNavigationService.showingFiltersPane">

          <a routerLink="/home/spaceconfigurator" *ngIf="canViewSpacebot()" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:true}" class="list-group-item list-group-item-action waves-effect"
            title="Space Configurator" data-toggle="tooltip" data-placement="bottom">
            <fa-icon [icon]="['fas', 'hospital']" size="m" style="font-size: 1.15rem"></fa-icon>
            <span class="bold">&nbsp;&nbsp;Space Configurator</span>
          </a>
   

          <a [routerLink]="['/home/catalog/view/home']" *ngIf="canViewCatalogue()" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:true}" class="list-group-item list-group-item-action waves-effect"
            title="Catalog" data-toggle="tooltip" data-placement="bottom">
            <fa-icon [icon]="['fas', 'archive']" size="s" style="margin-left:2px; font-size: 1.15rem"></fa-icon>
            <span class="bold">Catalog</span>
          </a>
          <ng-container *ngIf="canViewCatalogue()">
            @for (menuItem of catalogSubMenu(); track menuItem) {
            <ng-container>
              <ng-container *ngIf="!menuItem.IsHeading">
                <a [routerLink]="['/home/catalog/' + menuItem.Route]"
                   class="list-group-item list-group-item-action sub-menu-item" title="{{ menuItem.Name }}">
                  <fa-icon [icon]="['fas', menuItem.IconCode]" size="s" style="font-size: 1.15rem"></fa-icon>
                  <span>{{ menuItem.Name }}</span>
                </a>
                <ng-container *ngFor="let mi of menuItem.children">
                  <a [routerLink]="['/home/catalog/' + mi.Route]"
                     class="list-group-item list-group-item-action sub-sub-menu-item" title="{{ mi.Name }}">
                    <fa-icon [icon]="['fas', mi.IconCode]" size="s" style="font-size: 1.15rem"></fa-icon>
                    <span>{{ mi.Name }}</span>
                  </a>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="menuItem.IsHeading">
                <span class="menu-heading" title="{{ menuItem.Name }}">
                  {{ menuItem.Name }}
                </span>
              </ng-container>

            </ng-container>
            }
          </ng-container>

          <a [routerLink]="['/home/documents/view/home']" *ngIf="canViewLibrary()" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:true}" class="list-group-item list-group-item-action waves-effect"
            title="Library" data-toggle="tooltip" data-placement="bottom">
            <fa-icon [icon]="['fas', 'file']" size="s" style="margin-left:2px; font-size: 1.15rem"></fa-icon>
            <span class="bold">Library</span>
          </a>
          <ng-container *ngIf="canViewLibrary()">
            @for (menuItem of librarySubMenu(); track menuItem) {
            <ng-container *ngIf="!menuItem.IsHeading">
              <a [routerLink]="['/home/documents/view/' + menuItem.Route]"
                 class="list-group-item list-group-item-action sub-menu-item" title="{{ menuItem.Name }}">
                <fa-icon [icon]="['fas', menuItem.IconCode]" size="s" style="font-size: 1.15rem"></fa-icon>
                <span>{{ menuItem.Name }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="menuItem.IsHeading">
              <span class="menu-heading" title="{{ menuItem.Name }}">
                {{ menuItem.Name }}
              </span>
            </ng-container>
            }
          </ng-container>
        </div>

        <div id="libraryFiltersPane" *ngIf="inLibrary && libraryNavigationService.showingFiltersPane && canViewLibrary()">
          <div id="filters-list" style="display: flex;flex-direction: column;"
               *ngIf="!libraryNavigationService.loadingMain">
            <filters-pane [mode]="FiltersPaneMode.Navigation"
                          [companyNav]="libraryNavigationService.companyFilters"
                          [masterNav]="libraryNavigationService.masterFilters"
                          [programNav]="libraryNavigationService.programFilters"
                          [projectNav]="libraryNavigationService.projectFilters"
                          [documentCount]="libraryNavigationService.documentCount"
                          [initialFilters]="libraryNavigationService.currentURLWayFinders"
                          [initialPanel]="this.currentPanel()"
                          (filterChecked)="filterChecked()"
                          (panelOpened)="panelOpened($event)"
                          #filtersPane></filters-pane>

          </div>
        </div>

      </div>

    </div>
  </div>
  <div class="sticky-bottom">
    <div ngbDropdown class="col col-s-11 avatar d-inline-block">
      <!--avatar "-->
      <button type="button" class="btn" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"
        ngbDropdownToggle>
        <img src="assets/images/avatar.png" />
        <span title="{{ currentUser?.name}}">
          {{ currentUser?.name}}
        </span>
      </button>

      <div ngbDropdownMenu class="dropdown-menu-right current-account" aria-labelledby="dropdownMenuButton"
        style="width: 245px">

        <div style="margin-left:15px">
          <h6 class="pl-3 pt-2">{{ currentUser?.name}}</h6>
          <div class="pl-3 pr-3 current-account-username" *ngIf="currentUser?.idTokenClaims['email']">
            <span>{{currentUser?.idTokenClaims['email']}}</span>
          </div>
          <ng-container *ngIf="personaService.allPersonas() != null">
            <div id="persona-block">
              <label>Persona</label><br />
              <select id="config-persona" *ngIf="personaService.allPersonas().length > 1" (change)="personaSelected($event.target.value)">
                @for (persona of personaService.allPersonas(); track persona) {
                <option [selected]="persona == personaService.currentPersona()" value="{{ persona }}">{{ formatPersona(persona) }}</option>
                }
              </select>
              <div *ngIf="personaService.allPersonas().length == 1">{{ formatPersona(personaService.currentPersona()) }}</div>
            </div>
          </ng-container>
        </div>

        <div class="dropdown-divider"></div>

        <!-- <a ngbDropdownItem class="dropdown-item menu-item" (click)="openUserSettingsDialog()">
          <fa-icon [icon]="['fas', 'user-cog']" size="s"></fa-icon>&nbsp;&nbsp;User Settings
        </a> -->
        
        <a ngbDropdownItem (click)="logout()">
          <fa-icon [icon]="['fas', 'sign-out-alt']" size="s"></fa-icon>&nbsp;&nbsp;Sign Out
        </a>
      </div>
    </div>
    <!--//TODO:DIW:Re-enable collapse, this navigation bar needs a restructure first.-->
    <!-- <div class="col col-md-auto collapse-btn p-0">
      <button type="button" class="btn" (click)="toggleNavbar()">
        <span>
          <fa-icon class="d-none double-left" [icon]="['fas', 'angle-double-left']" size="s"></fa-icon>
          <fa-icon class="double-right" [icon]="['fas', 'angle-double-right']" size="s"></fa-icon>
        </span>
      </button>
    </div> -->
  </div>
</header>