import {
  computed,
  signal,
  WritableSignal,
} from '@angular/core';

import {
  FilterV2Dto,
  FilterContext
} from '../';

import {
  ReferenceType,
} from '../../_enums/referenceType';

export class FilterV2 {
  id: string;
  name: string;
  thumbnailUrl: string | null;
  referenceType: ReferenceType;
  parentId: string | null;
  group: string | null;
  children: FilterV2[];
  checked: WritableSignal<boolean>;
  context: FilterContext;
  public visible: WritableSignal<boolean> = signal<boolean>(true);
  isAdding: boolean = false;
  isEditing: boolean = false;
  public willBeVisible: boolean;
  public enabled: WritableSignal<boolean> = signal<boolean>(true);
  itemCount: number;
  implied: WritableSignal<boolean> = signal(false);
  directCount: number;

  public checkedCount = computed<number>(() => {
    return this.children.filter(c => c.checked()).length + (this.checked() ? 1 : 0);
  });

  totalCount = computed<number>(() => {
    return 1 + this.children.length;
  });

  private constructor(dto: FilterV2Dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.thumbnailUrl = dto.thumbnailUrl;
    this.referenceType = dto.referenceType;
    this.parentId = dto.parentId;
    this.group = dto.group;
    this.children = [];
    this.checked = signal<boolean>(dto.checked);
    this.visible = signal(true);
    this.context = dto.context;
    this.itemCount = dto.itemCount;
    this.directCount = dto.directCount;
    this.implied.set(dto.implied);
    
    
    if (this.context != FilterContext.Company && this.context != FilterContext.MasterCatalog && this.context != FilterContext.ProgramCatalog && this.context != FilterContext.ProjectCatalog) {
      throw new Error('Invalid context');
    }
    if (dto.children) {
      dto.children.forEach(c => this.children.push(FilterV2.FromFilterV2Dto(c)));
    }
  }
  
  public static FromFilterV2Dto(filter: FilterV2Dto): FilterV2 {
    return new FilterV2(filter);
  }

  public static FromFilterV2Dtos(filters: FilterV2Dto[]): FilterV2[] {
    let result: FilterV2[] = [];
    filters.forEach((filter) => {
      result.push(FilterV2.FromFilterV2Dto(filter));
    })
    return result;
  }

  getChecked(): FilterV2[] {
    let allChecked: FilterV2[] = [];
    if (this.checked()) allChecked.push(this);
    this.children.filter(c => c.checked()).forEach(c => allChecked.push(c));
    return allChecked;
  }

  uncheckAll() {
    this.checked.set(false);
    this.children.forEach(c => c.uncheckAll());
  }

  clearChecked(): void {
    if (this.checked()) this.checked.set(false);
    this.children.forEach(c => c.clearChecked());
  }

  sort(): void {
    this.children.sort((a, b) => a.name.localeCompare(b.name));
  }
}
